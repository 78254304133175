import React from 'react'
import FestifHelmet from '../components/Helmet/FestifHelmet'
import Wolflayout from '../components/wolflayout'
import Banner from '../components/NewTheme/Ui/Banner'
import ColorButton from '../components/Ui/ColorButton'
import getTicketURL from '../utils/TicketURL'
import WhiteButton from '../components/Ui/WhiteButton'
import Collapsible from '../components/Ui/Collapsible'

const sections = [
  {
    title: 'Informations générales',
    content: (
      <ul>
        <li><p>On te rappelle que 45% de la programmation est gratuite! Les spectacles gratuits ne nécessitent pas de billet.</p></li>
        <li><p>Il y a des frais postaux de 5.95$ pour les envois de Passeports.</p></li>
        <li><p>Les spectacles sont gratuits pour les 10 ans et moins.</p></li>
        <li><p>Les Passeports seront envoyés en mai.</p></li>
        <li><p>Le Bus Festif! Menaud est accessible aux 18 ans et plus.</p></li>
        <li><p>Le Bus Festif! Menaud est une exclusion du Passeport La Totale. Un billet spécifique sera nécessaire.</p></li>
      </ul>
    )
  },
  {
    title: 'Prévente locale',
    content: (
      <ul>
        <li><p>9 avril de 7h à 18h au Pavillon Jacques St-Gelais du Musée d’art contemporain de Baie-Saint-Paul</p></li>
        <li><p>Maximum d’achat de 2 Passeports La Totale par personne</p></li>
        <li><p>Maximum d’achat de 4 Passeports Place Desjardins</p></li>
        <li><p>Maximum d’achat de 4 billets par show par personne</p></li>
        <li><p>Les spectacles sont gratuits pour les enfants de 10 ans et moins, nul besoin de billet.</p></li>
      </ul>
    )
  },
  {
    title: 'Ouverture de la billetterie en ligne',
    content: (
      <ul>
        <li><p>10 avril à midi</p></li>
        <li><p>Maximum d’achat de 2 Passeports La Totale par personne</p></li>
        <li><p>Maximum d’achat de 4 Passeports Place Desjardins</p></li>
        <li><p>Maximum d’achat de 4 billets par show par personne</p></li>
        <li><p>Maximum d’achat de 2 terrains de camping par personne</p></li>
      </ul>
    )
  },
  {
    title: 'Un Festif! ensemble',
    content: (
      <p className='!pl-4'>Chaque année, Le Festif! contribue au bien-être collectif en présentant diverses activités culturelles axées sur la musique.</p>
    )
  },
  {
    title: 'Politique de dons et commandites',
    content: (
      <p className='!pl-4'>Le Festif! offre des billets ou passeports pour divers projets du festival, mais aucune commandite monétaire.</p>
    )
  },
  {
    title: 'Politique de prise de photos et vidéos',
    content: (
      <p className='!pl-4'>Le Festif! est un événement public pour lequel des photographies et des vidéos de la foule seront prises et qui seront utilisées, notamment, pour des fins publicitaires. En procédant à l’achat de tout type de billet et en vous présentant sur l’un ou l’autre des sites du Festif!, vous reconnaissez et acceptez que des photographies ou des vidéos vous incluant pourraient être utilisées et publiées, et ce, sans autre avis. Il s’agit d’une modalité essentielle de l’achat de votre billet.</p>
    )
  }
];

const Billetterie = () => {
  const metaTitle = ''
  const metaDescrition = ''
  const metaKeywords = ''

  return (
    <Wolflayout mobileTitle={'Billetterie'}>
      <FestifHelmet title={metaTitle} description={metaDescrition} keywords={metaKeywords}>
        <script src="https://lepointdevente.com/plugins/cart/js?locale=fr_CA&amp;ga=UA-32197713-2"></script>
      </FestifHelmet>

      <Banner title="Billetterie" />

      <div className="container billetterie pb-2">
        
          {/* TICKETS BUTTON */}
          <div className="mt-0 mb-20 !text-center md:text-left !mx-0 !px-0">
            <h2 className='px-2'>Ouverture de la billetterie<br/>le 10 avril à midi</h2>
          </div>

          <h2 className='hidden'>Passeports et billets</h2>
          <div className="flex flex-wrap w-full">

            {/* PASSEPORT LA TOTALE */}
            <div className='px-4 md:w-1/3 w-full'>
              <div className='shadow w-full text-center md:my-0 my-2 border-4 border-purple-wolf2025 rounded-3xl overflow-hidden'>
                <a href={getTicketURL()} target="_blank" rel="noreferrer" className="w-full h-full">
                  <div >
                    <div className="bg-yellow-wolf2025 px-1 py-6 border-b-4 border-purple-wolf2025">
                      <h4 className="leading-none !text-purple-wolf2025">Passeport</h4>
                      <h3 className="leading-none mb-3 !text-purple-wolf2025">La<br/>Totale</h3>
                    </div>
                    <div className="!text-purple-wolf2025 py-4 px-6 bg-white">
                      <h5 className='!text-purple-wolf2025'>310$ <span className='uppercase !text-purple-wolf2025 !text-xs'>Frais en sus</span></h5>
                      <br/>
                      <p>Ce Passeport te donne accès à tous les spectacles de la programmation du 17 au 19 juillet 2025 - à l’exception du Bus Festif! Menaud.</p>
                      <br/>
                      <p>Tu recevras ton Passeport par la poste chez toi.</p>
                      <br/>
                      <h6>Tu devras présenter ton Passeport La Totale afin de pouvoir entrer sur les sites et accéder aux navettes.</h6>
                      <WhiteButton text="Acheter" className="mx-auto w-1/3 !bg-yellow-wolf2025 mt-4 !font-[400]" />
                      <div className='hidden md:block'>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>

            {/* PASSEPORT PLACE DESJARDINS */}
            <div className='px-4 md:w-1/3 w-full'>
              <div className='shadow w-full text-center md:my-0 my-2 border-4 border-purple-wolf2025 rounded-3xl overflow-hidden'>
                <a href={getTicketURL()} target="_blank" rel="noreferrer" className=" w-full h-full">
                  <div >
                    <div className="bg-blue-wolf2025 px-1 py-6 border-b-4 border-purple-wolf2025">
                      <h4 className="leading-none !text-purple-wolf2025">Passeport</h4>
                      <h3 className="leading-none mb-3 !text-purple-wolf2025">Place<br/>Desjardins</h3>
                    </div>
                    <div className="!text-purple-wolf2025 py-4 px-6 bg-white">
                      <h5 className='!text-purple-wolf2025'>110$ <span className='uppercase !text-purple-wolf2025 !text-xs'>Frais en sus</span></h5>
                      <br/>
                      <p>Ce Passeport te donne accès à tous les spectacles de la Place Desjardins du 17 au 20 juillet 2025.</p>
                      <br/>
                      <p>Tu recevras ton Passeport par la poste chez toi.</p>
                      <br/>
                      <h6>Tu devras présenter ton Passeport Desjardins afin de pouvoir entrer sur le site.</h6>
                      <div className='hidden md:block'>
                        <p><br/></p>
                        <p><br/></p>
                      </div>
                      <WhiteButton text="Acheter" className="mx-auto w-1/3 !bg-blue-wolf2025 mt-4 !font-[400]" />
                    </div>
                  </div>
                </a>
              </div>
            </div>

            {/* BILLETS UNITAIRES */}
            <div className='px-4 md:w-1/3 w-full'>
              <div className='shadow w-full text-center md:my-0 my-2 border-4 border-purple-wolf2025 rounded-3xl overflow-hidden'>
                <a href={getTicketURL()} target="_blank" rel="noreferrer" className=" w-full h-full">
                  <div >
                    <div className="bg-pink-wolf2025 px-1 py-6 border-b-4 border-purple-wolf2025">
                      <h4 className="leading-none !text-purple-wolf2025">Les</h4>
                      <h3 className="leading-none mb-3 !text-purple-wolf2025">Billets<br/>unitaires</h3>
                    </div>
                    <div className="!text-purple-wolf2025 py-4 px-6 bg-white">
                      <h5 className='!text-purple-wolf2025'>20$ à 45$</h5>
                      <h5 className='!leading-3'><span className='uppercase !text-purple-wolf2025 !text-xs'>Frais en sus</span></h5>
                      <br/>
                      <p>Tous les spectacles payants sont disponibles en billets unitaires.</p>
                      <br/>
                      <p>Tu recevras ton billet électronique par courriel.</p>
                      <br/>
                      <h6>Tu devras présenter ton billet électronique (code QR) pour entrer sur le site de ton spectacle.</h6>
                      <div className='hidden md:block'>
                        <p><br/></p>
                        <p><br/></p>
                      </div>
                      <WhiteButton text="Acheter" className="mx-auto w-1/3 !bg-pink-wolf2025 mt-4 !font-[400]" />
                    </div>
                  </div>
                </a>
              </div>
            </div>

          </div>

          <div className="flex flex-wrap w-full mt-8">

          {/* ORANGE BISTRO */}
          <div className='px-4 md:w-1/2 w-full'>
            <div className='shadow w-full text-center md:my-0 my-2 border-4 border-purple-wolf2025 rounded-3xl overflow-hidden'>
              <a href={'https://orangebistro.com/forfaits/'} target='_blank' rel="noreferrer" className="">
                <div className="bg-white">
                  <div className="bg-pink-wolf2025 px-1 py-6 text-white border-b-4 border-purple-wolf2025">
                    <h4 className="leading-none !text-purple-wolf2025">Réservation</h4>
                    <h3 className="leading-none mb-3 !text-purple-wolf2025">Souper-spectacle à<br/> l’Orange Bistro</h3>
                  </div>
                  <div className="!text-purple-wolf2025 py-4 px-6">
                    <h5 className='!text-purple-wolf2025 mb-4'>100$ à 130$ <span className='uppercase !text-purple-wolf2025 !text-xs'>Frais en sus</span></h5>
                    <p>Réservation via le restaurant uniquement.</p>
                    <div className='hidden md:block'>
                      <p><br/></p>
                    </div>
                    <WhiteButton text="Acheter" className="mx-auto w-1/4 !bg-pink-wolf2025 mt-6 mb-1 !font-[400]" />
                  </div>
                </div>
              </a>
            </div>
          </div>

          {/* CAMPINGS */}
          <div className='px-4 md:w-1/2 w-full'>
            <div className='shadow w-full text-center md:my-0 my-2 border-4 border-purple-wolf2025 rounded-3xl overflow-hidden'>
              <a href={getTicketURL()} target='_blank' rel="noreferrer" className="">
                <div className="bg-white">
                  <div className="bg-yellow-wolf2025 px-1 py-6 text-white border-b-4 border-purple-wolf2025">
                    <h4 className="leading-none !text-purple-wolf2025">Réservation</h4>
                    <h3 className="leading-none mb-3 !text-purple-wolf2025">Campings<br/>sur place</h3>
                  </div>
                  <div className="!text-purple-wolf2025 py-4 px-6">
                    <h5 className='!text-purple-wolf2025 mb-4'>45$ à 220$ <span className='uppercase !text-purple-wolf2025 !text-xs'>Frais en sus</span></h5>
                    <p className=''>Prix par terrain et non par personne.</p>
                    <p>Pour toutes informations, rendez-vous sur la page <a className="italic underline hover:no-underline" href="/infos/hebergements#info_content">hébergement</a></p>
                    <div className='hidden md:block'>
                    </div>
                    <WhiteButton text="Acheter" className="mx-auto w-1/4 !bg-yellow-wolf2025 mt-6 mb-1 !font-[400]" />
                  </div>
                </div>
              </a>
            </div>
          </div>

        </div>

        <div className='w-96 mt-16'>
          <ColorButton text="Voir les prix" href="https://le-festif-ca.cdn.prismic.io/le-festif-ca/Z_QzzndAxsiBwa0V_prix-pdf.pdf" externalLink={true} className="mb-9" />
          <ColorButton text="Voir les prix (Camping)" href="https://le-festif-ca.cdn.prismic.io/le-festif-ca/Z_QzzXdAxsiBwa0U_prix-camping-pdf.pdf" externalLink={true} className="mt-6" />
        </div>

        <div className="md:px-[30px] px-[10px] mt-24">
          {sections.map((section, index) => (
            <Collapsible  key={index} title={section.title}>
              <div className='py-2 px-3'>{section.content}</div>
            </Collapsible>
          ))}

            <Collapsible title="Politique de remboursement">
              <div className='py-2 px-3 !pl-4'>
                <h6>Vente finale</h6>
                <p>Avant de procéder à l’achat d’un passeport ou d’un billet unitaire, l’acheteur.euse reconnait avoir lu la présente politique de remboursement. L’acheteur.euse reconnait également que l’achat constitue une vente finale et qu’aucun échange ou remboursement ne sera accordé, sauf dans les cas présents. Finalement l’acheteur.euse reconnait que Le Festif! appliquera la présente politique de remboursement de manière stricte, de manière à être juste avec toutes et tous et, en conséquence, aucune exception ne pourra être faite.</p>
                <h6 className='pt-2'>Passeports La Totale</h6>
                <p>Puisqu'ils offrent une multitude d’avantages et l’accès aux diverses scènes ou divers spectacles pour l’ensemble du festival, aucun remboursement des passeports ne sera effectué, pour quelque raison que ce soit, sauf dans l’éventualité où le festival était annulé en totalité, et ce, avant le début de celui-ci. Dans ce cas, la demande de remboursement devra être effectuée au plus tard dans les dix (10) jours suivant la date à laquelle le festival devait débuter. Dans l’éventualité où le festival serait annulé alors que ce dernier était commencé, aucun remboursement ne sera effectué.</p>
                <h6 className='pt-2'>Passeports Place Desjardins</h6>
                <p>Puisqu'ils offrent l’accès à divers spectacles pour l’ensemble du festival, aucun remboursement des passeports ne sera effectué, pour quelque raison que ce soit, sauf dans l’éventualité où deux des trois soirées de spectacles à la Place Desjardins seraient annulées en totalité. Dans ce cas les passeports seraient remboursés à 50%, et ce, sur demande. La demande de remboursement devra être effectuée au plus tard dans les dix (10) jours suivant la date à laquelle le festival devait débuter. Dans l’éventualité où le festival était annulé dans son entièreté avant le début de celui-ci, les passeports seraient remboursés en totalité, et ce, sur demande dans les dix (10) jours suivant la date à laquelle le festival devait débuter.</p>
                <h6 className='pt-2'>Billet unitaire</h6>
                <p>Aucun remboursement de billet unitaire ne sera effectué, sauf dans les cas suivants :</p>
                <ul>
                  <li><p>Advenant l’annulation de l’intégralité du spectacle avant le début de celui-ci, il sera possible d’obtenir un remboursement à la condition de faire la demande de remboursement dans les dix (10) jours suivant l’événement annulé. Cependant, dès que le ou la premier.ère artiste agissant à titre de première partie a débuté le spectacle, aucun remboursement ne sera effectué.</p></li>
                  <li><p>Dans l’éventualité où la tête d’affiche de la soirée serait changée, pour quelque raison que ce soit, plus de cinq (5) jours avant l’événement, le billet unitaire pourra être remboursé à la condition que la demande soit formulée au plus tard vingt-quatre (24) heures avant l’événement. Cependant, si la tête d’affiche était changée dans les cinq (5) jours précédents la soirée, aucun remboursement ne sera effectué.</p></li>
                </ul>
                <p>Pour plus de précision, le terme « tête d’affiche » réfère à l’artiste principal de la soirée et qui est le dernier à offrir une prestation sur la scène visée par le billet unitaire. Quant au terme « première partie » réfère au premier artiste à offrir une prestation sur la scène visée par le billet unitaire.</p>
                <h6 className='pt-2'>Autres modalités</h6>
                <p>Il est entendu que, dans l’éventualité où il y avait remboursement d'un passeport ou d’un billet unitaire, les frais de service ne seront pas remboursés et le passeport ou le billet unitaire deviendra nul. Il est également entendu que la demande de remboursement devra être faite à l’aide du formulaire prévu à cet effet et que celle-ci devra être faite uniquement par l’acheteur.euse initial.e du passeport ou du billet unitaire, et non du détenteur ou de la détentrice le jour de l’événement. Toutes les demandes de remboursement effectuées hors des délais prévus seront refusées. Les client.e.s reconnaissent que Le Festif! est un festival extérieur et que par le fait même, aucun remboursement ne sera effectué en raison des conditions météorologiques, incluant notamment, mais non limitativement, des orages et des vents violents. Le festival se réserve le droit de modifier la politique de remboursement sans préavis. La version présente sur le site du Festif! est considérée comme étant la version officielle.</p>
              </div>
            </Collapsible>

            <div id="politique-remboursement"></div>

          <ColorButton text="Promesse des festivalier·ères" href="https://forms.gle/jewnQgDyrdeRhApeA" externalLink={true} className="w-96 mt-12 mb-9" />
        </div>

      </div>
    </Wolflayout>
  )
}

export default Billetterie
